/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FacebookNegative = ({ className }) => {
  return (
    <svg
      className={`facebook-negative ${className}`}
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2_12675)">
        <path
          className="path"
          d="M37.3195 18.9003C37.3195 8.86153 29.1815 0.723511 19.1427 0.723511C9.10396 0.723511 0.965942 8.86153 0.965942 18.9003C0.965942 27.9727 7.6129 35.4927 16.3026 36.8563V24.1545H11.6874V18.9003H16.3026V14.8957C16.3026 10.3402 19.0164 7.82382 23.1683 7.82382C25.1563 7.82382 27.2371 8.17884 27.2371 8.17884V12.652H24.9451C22.6872 12.652 21.9829 14.0533 21.9829 15.4922V18.9003H27.0241L26.2182 24.1545H21.9829V36.8563C30.6726 35.4927 37.3195 27.9727 37.3195 18.9003Z"
          fill="white"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2_12675">
          <rect
            className="rect"
            fill="white"
            height="36.3536"
            transform="translate(0.965942 0.723511)"
            width="36.3536"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
