/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const GoogleNegative = ({ className }) => {
  return (
    <svg
      className={`google-negative ${className}`}
      fill="none"
      height="38"
      viewBox="0 0 37 38"
      width="37"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2_12676)">
        <path
          className="path"
          clipRule="evenodd"
          d="M25.7718 10.6418C23.895 8.85181 21.3904 7.87668 18.799 7.91676C14.0569 7.91676 10.0295 11.116 8.59353 15.4239V15.424C7.83213 17.6814 7.83213 20.1259 8.59353 22.3834H8.60019C10.0429 26.6847 14.0636 29.8839 18.8057 29.8839C21.2535 29.8839 23.355 29.2578 24.9837 28.1519V28.1474C26.9006 26.8784 28.2097 24.8814 28.6171 22.6239H18.799V15.6243H35.9439C36.1576 16.8399 36.2578 18.0822 36.2578 19.3178C36.2578 24.8463 34.282 29.5205 30.8442 32.6863L30.8478 32.6891C27.8356 35.4675 23.7013 37.0771 18.799 37.0771C11.9263 37.0771 5.64141 33.2033 2.55573 27.0654V27.0653C-0.0223274 21.9292 -0.0223211 15.8781 2.55575 10.742H2.55579L2.55573 10.742C5.64141 4.59731 11.9263 0.723515 18.799 0.723515C23.3139 0.670083 27.6753 2.36654 30.9614 5.45222L25.7718 10.6418Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2_12676">
          <rect
            className="rect"
            fill="white"
            height="36.3536"
            transform="translate(0.258667 0.723511)"
            width="36.3536"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
