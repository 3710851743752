import React, { useEffect } from "react";
import { AppleNegative } from "../../icons/AppleNegative";
import { FacebookNegative } from "../../icons/FacebookNegative";
import { GoogleNegative } from "../../icons/GoogleNegative";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="element">
      <div className="div">
        <div className="overlap">
          <div className="rectangle" />
          <div className="text-wrapper">Mobile App</div>
          <img className="img" alt="Element" src="/img/01.png" />
          <div className="onboarding">
            <div className="overlap-group">
              <div className="group">
                <div className="text-wrapper-2">01 | Homepage</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-3">Fintech</div>
        <div className="hero">
          <p className="p">
            fuse.io offers a holistic investment environment by bringing in your
            crypto, stocks, and commodity investments in one place integrated
            with your crypto wallets and e-wallets and your bank accounts in one
            place. Moreover, with fuse.io, you can manage your portfolio,
            personal finances, goals and initiate transactions with ease. We
            were assigned to bring the idea to life and design a MVP that helps
            the end-user manage, grow and put their money to work with ease.
          </p>
          <div className="text-wrapper-4">Investing made simple</div>
        </div>
        <div className="UIX">
          <div className="overlap-2">
            <div className="text-wrapper-5">UI/UX</div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group-2">
                <img className="line" alt="Line" src="/img/line-18-2.svg" />
                <img className="line-2" alt="Line" src="/img/line-19-2.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="WD">
          <div className="overlap-3">
            <div className="text-wrapper-6">App Developement</div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group-2">
                <img className="line" alt="Line" src="/img/line-18-1.svg" />
                <img className="line-2" alt="Line" src="/img/line-19-1.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="SM">
          <div className="overlap-4">
            <div className="text-wrapper-7">Content</div>
            <div className="overlap-group-wrapper">
              <div className="overlap-group-2">
                <img className="line" alt="Line" src="/img/line-18.svg" />
                <img className="line-2" alt="Line" src="/img/line-19.svg" />
              </div>
            </div>
          </div>
        </div>
        <img className="group-2" alt="Group" src="/img/group-1754.png" />
        <div className="onboarding-2">
          <div className="div-wrapper">
            <div className="text-wrapper-8">00 | Onboarding</div>
          </div>
          <div className="group-3">
            <div className="element-login">
              <div className="overlap-5">
                <div className="UI-btn">
                  <div className="overlap-6">
                    <div className="IC-chevronright">
                      <div className="overlap-group-3">
                        <img
                          className="vector"
                          alt="Vector"
                          src="/img/vector-3.svg"
                        />
                        <img
                          className="vector-2"
                          alt="Vector"
                          src="/img/vector-2.svg"
                        />
                      </div>
                    </div>
                    <div className="text-wrapper-9">Log In</div>
                  </div>
                </div>
                <div className="group-4">
                  <div className="form-list">
                    <div className="UI-form-default">
                      <div className="form">
                        <img
                          className="img-2"
                          alt="Ic mail"
                          src="/img/ic-24-mail.svg"
                        />
                        <div className="fill">
                          <div className="text-wrapper-10">Email</div>
                        </div>
                      </div>
                    </div>
                    <div className="UI-form-default">
                      <div className="form">
                        <img
                          className="img-2"
                          alt="Ic password"
                          src="/img/ic-24-password.svg"
                        />
                        <div className="fill">
                          <div className="text-wrapper-10">Password</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="divider" />
                  <div className="social">
                    <div className="img-wrapper">
                      <img className="img-3" alt="Frame" src="/img/frame.svg" />
                    </div>
                    <div className="img-wrapper">
                      <img
                        className="img-3"
                        alt="Ic social"
                        src="/img/ic-social-logofacebook.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="title">
                  <p className="text-wrapper-11">Great to have you back!</p>
                </div>
                <div className="UI-title-nav">
                  <div className="IC-close-wrapper">
                    <div className="IC-close">
                      <div className="vector-wrapper">
                        <img
                          className="vector-3"
                          alt="Vector"
                          src="/img/vector.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="create-account">
                  <div className="div-2">
                    <div className="div-2">
                      <div className="time">9:41</div>
                      <div className="battery">
                        <img
                          className="cap"
                          alt="Cap"
                          src="/img/cellular-connection-2.png"
                        />
                      </div>
                      <img
                        className="wifi"
                        alt="Wifi"
                        src="/img/cellular-connection-2.png"
                      />
                      <img
                        className="cellular-connection"
                        alt="Cellular connection"
                        src="/img/cellular-connection-2.png"
                      />
                    </div>
                    <div className="div-2">
                      <div className="time-2">9:41</div>
                      <div className="battery">
                        <div className="capacity-wrapper">
                          <div className="capacity" />
                        </div>
                        <img className="cap-2" alt="Cap" src="/img/cap-1.svg" />
                      </div>
                      <img
                        className="wifi-2"
                        alt="Wifi"
                        src="/img/wifi-1.svg"
                      />
                      <img
                        className="cellular-connection-2"
                        alt="Cellular connection"
                        src="/img/cellular-connection-1.svg"
                      />
                    </div>
                  </div>
                  <div className="group-5">
                    <div className="overlap-group-4">
                      <p className="page-title">
                        <span className="span">Username</span>
                      </p>
                      <div className="rectangle-2" />
                    </div>
                  </div>
                  <div className="overlap-wrapper">
                    <div className="overlap-7">
                      <div className="page-title-2">Password</div>
                      <div className="rectangle-3" />
                    </div>
                  </div>
                  <div className="page-title-3">Or Sign In with</div>
                  <button className="button">
                    <div className="page-title-4">Login</div>
                  </button>
                  <div className="group-6">
                    <p className="page-title-5">Sign in with your account</p>
                    <div className="login">Welcome back</div>
                  </div>
                  <div className="group-7">
                    <div className="text-wrapper-12">Forgot your password?</div>
                    <div className="text-wrapper-13">Reset here</div>
                  </div>
                  <FacebookNegative className="facebook-negative" />
                  <GoogleNegative className="google-negative" />
                  <AppleNegative className="apple-negative" />
                  <img className="line-3" alt="Line" src="/img/line-1.svg" />
                  <img className="line-4" alt="Line" src="/img/line-2.svg" />
                  <img
                    className="group-8"
                    alt="Group"
                    src="/img/group-1806-1.png"
                  />
                </div>
                <div className="OS-homeindicator">
                  <div className="home-indicator" />
                </div>
              </div>
            </div>
            <div className="element-signup">
              <div className="overlap-8">
                <div className="create-account-2">
                  <div className="group-9">
                    <div className="overlap-group-5">
                      <div className="page-title-6">Full name</div>
                      <div className="rectangle-4" />
                    </div>
                  </div>
                  <div className="group-10">
                    <div className="overlap-group-5">
                      <div className="page-title-7">Email address</div>
                      <div className="rectangle-4" />
                    </div>
                  </div>
                  <div className="group-11">
                    <div className="overlap-7">
                      <div className="page-title-8">Password</div>
                      <div className="rectangle-5" />
                    </div>
                  </div>
                  <div className="page-title-9">Already have an account?</div>
                  <button className="page-title-wrapper">
                    <div className="page-title-10">Sign Up</div>
                  </button>
                  <div className="group-12">
                    <p className="page-title-5">
                      Let’s get started by creating your account.
                    </p>
                    <div className="login-2">Welcome!</div>
                  </div>
                  <div className="system-light-status">
                    <div className="time-3">9:41</div>
                    <div className="battery">
                      <div className="capacity-wrapper">
                        <div className="capacity" />
                      </div>
                      <img className="cap-2" alt="Cap" src="/img/cap.svg" />
                    </div>
                    <img className="wifi-3" alt="Wifi" src="/img/wifi.svg" />
                    <img
                      className="cellular-connection-2"
                      alt="Cellular connection"
                      src="/img/cellular-connection.svg"
                    />
                  </div>
                  <img
                    className="group-13"
                    alt="Group"
                    src="/img/group-1806.png"
                  />
                </div>
                <div className="home-indicator-wrapper">
                  <div className="home-indicator-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-9">
          <div className="group-14" />
          <p className="text-wrapper-14">
            Investing primarily is data driven, that means keeping the users
            updated on the current trends, news and media in real time. Which
            gave birth to the core pillars in every aspects of UX and UI -
            Simple and faster navigation, seamless accessiblilty and most
            importantly easy investing.
          </p>
          <div className="head">
            <p className="watch-the-markets">
              <span className="text-wrapper-15">Watch the </span>
              <span className="text-wrapper-16">Markets</span>
            </p>
            <div className="rectangle-6" />
          </div>
          <div className="base">
            <div className="overlap-10">
              <div className="base-2" />
              <img className="img-4" alt="Element" src="/img/02.png" />
            </div>
          </div>
          <div className="product-page">
            <div className="group-15" />
          </div>
        </div>
        <div className="overlap-11">
          <img
            className="mask-group"
            alt="Mask group"
            src="/img/mask-group-1.png"
          />
          <p className="text-wrapper-17">All your assets in one place</p>
          <div className="outro">
            <div className="group-wrapper">
              <div className="group-16">
                <div className="logo-wrapper">
                  <img className="logo" alt="Logo" src="/img/logo.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
