/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const AppleNegative = ({ className }) => {
  return (
    <svg
      className={`apple-negative ${className}`}
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2_12677)">
        <path
          className="path"
          d="M33.682 29.0544C33.1323 30.3245 32.4815 31.4936 31.7275 32.5685C30.6998 34.0338 29.8583 35.0481 29.2098 35.6113C28.2045 36.5359 27.1274 37.0093 25.974 37.0363C25.146 37.0363 24.1474 36.8007 22.985 36.3227C21.8188 35.847 20.7471 35.6113 19.7671 35.6113C18.7394 35.6113 17.6372 35.847 16.4582 36.3227C15.2774 36.8007 14.3262 37.0497 13.5989 37.0744C12.4928 37.1215 11.3904 36.6346 10.2899 35.6113C9.58757 34.9987 8.70905 33.9486 7.65663 32.4608C6.52746 30.8721 5.59912 29.0297 4.87185 26.9294C4.09297 24.6607 3.70251 22.4639 3.70251 20.337C3.70251 17.9007 4.22895 15.7995 5.2834 14.0386C6.1121 12.6242 7.21456 11.5085 8.59438 10.6895C9.97421 9.87041 11.4651 9.45303 13.0707 9.42633C13.9492 9.42633 15.1012 9.69807 16.5329 10.2321C17.9605 10.768 18.8772 11.0397 19.2791 11.0397C19.5795 11.0397 20.5979 10.722 22.3242 10.0885C23.9567 9.50105 25.3345 9.2578 26.4632 9.35362C29.5217 9.60046 31.8195 10.8062 33.3477 12.9783C30.6123 14.6357 29.2592 16.9571 29.2861 19.9351C29.3108 22.2547 30.1523 24.185 31.8061 25.7176C32.5556 26.429 33.3926 26.9787 34.3238 27.3692C34.1219 27.9549 33.9087 28.5159 33.682 29.0544ZM26.6674 1.45122C26.6674 3.26931 26.0032 4.96686 24.6792 6.5381C23.0815 8.40599 21.149 9.48534 19.0533 9.31503C19.0266 9.09691 19.0111 8.86735 19.0111 8.62612C19.0111 6.88075 19.771 5.01286 21.1203 3.48561C21.7939 2.71233 22.6507 2.06937 23.6896 1.55646C24.7263 1.0512 25.707 0.771787 26.6292 0.723938C26.6562 0.966989 26.6674 1.21006 26.6674 1.45119V1.45122Z"
          fill="white"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2_12677">
          <rect
            className="rect"
            fill="white"
            height="36.3536"
            transform="translate(0.673096 0.723511)"
            width="36.3536"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
